<template>
	<div v-loading.lock="fullscreenLoading">
		
		
		<div class="bbox ycenter xright">
			<el-button class="right" v-if="type=='view'" size="mini" type="primary" @click="type='edit'">修改文章</el-button>
		</div>
		
		<el-form size="small" :model="articleForm" :rules="rules" ref="articleForm" label-width="250px" class="">
			<el-card class="box-card mt-2" v-if="currentIndex == 0">
				
				<div style="padding-top: 50px;">
				
					<el-form-item label="文章标题：" prop="title">
						<div v-if="type=='view'">{{articleForm.title}}</div>
						<el-input v-else maxlength="24" show-word-limit
											:class="{'disabled':type=='view'}"
											:disabled="type=='view'"
											placeholder="请输入文章标题"
											class="width278"
											size="small"
											v-model="articleForm.title"></el-input>
					</el-form-item>
					
					<el-form-item label="文章封面：" prop="cover">
						<div class="elUpload docPhoto">
							<el-upload
								class="avatar-uploader" :disabled="type=='view'"
								:action="actionPath"
								:data="qiniuData"
								:show-file-list="false"
								:on-success="uploadDocPhotoSuccess"
								:before-upload="beforeAvatarUpload">
								<img v-if="articleForm.cover" :src="articleForm.cover | filterImg" class="avatar">
								<div class="imgUpload" v-if="type!='view' && !articleForm.cover"></div>
							
							</el-upload>
						</div>
						<div v-if="type!='view'" style="color: #ccc; font-size: 12px;">只能上传jpg/png格式文件，文件不能超过2M</div>
					
					</el-form-item>
					
					<el-form-item label="文章分类：" prop="cate_id">
						<el-select v-model="articleForm.cate_id"
											 clearable
											 size="small"
											 placeholder="请选择"
											 :disabled="type=='view'"
						>
							<el-option
								v-for="item in articleCate"
								:key="item.id"
								:label="item.title"
								:value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="文章内容：" prop="content">
						<div v-if="type=='view'">
							<pre v-html="articleForm.content" class="preContent"></pre>
						</div>
						<div v-else>
							<tinymce v-model="articleForm.content" :height="300"/>
						</div>
					</el-form-item>
					
					
					<el-form-item label="文章链接：" prop="link">
						<div v-if="type=='view'">
							{{articleForm.link}}
						</div>
						<el-input v-else v-model="articleForm.link" class="width278" :disabled="type=='view'" maxlength="100"
											show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="排序：" prop="sort">
						<el-input v-model="articleForm.sort" style="width: 217px;" maxlength="2"
											:disabled="type=='view'"></el-input>
						<span style="color: #999;padding-left: 10px; font-size: 12px;">数字越小排序越靠前</span>
					</el-form-item>
					
					<div label="" v-if="type!='view'">
						<el-input type="hidden" v-model="articleForm.cover"></el-input>
					</div>
					
					<div class="text-center bbox xcenter" v-if="type!=='view'">
						<el-button size="mini" type="info" v-if="type=='edit'" @click="cancelForm">放弃修改</el-button>
						<el-button size="mini" type="primary" @click="saveForm('articleForm')">确定</el-button>
					</div>
				</div>
			</el-card>
		</el-form>
	
	</div>
</template>

<script>
import config from "@/utils/config";
import {getQNToken} from "@/api/basic";
import {merchantsList, cateList, addArticle, editArticle, detailArticle} from "@/api/article";
import Tinymce from '@/components/Tinymce'
import {mapGetters} from 'vuex';

export default {
  name: "articleDetail",
  created () {
	  let params = this.$route.query
	  console.log(params,'params')
    this.initPage()
  },
  components: { Tinymce },
  computed: {
    ...mapGetters(["adminId"]),
  },
  data () {
    return {
      currentIndex: 0,
      articleCate: [
        {
          id: 31,
          title: "患者端文章",
        },
        {
          id: 37,
          title: "医生端文章",
        }
      ],
      merchantsListDropDownData: [],
      pageName: '',
      fullscreenLoading: false,
      actionPath: 'http://upload.qiniup.com',
      qiniuData: {
        token: "",
        key: ""
      },
      type: 'add',
      id: '',
      articleForm: {
        agent_id: '',
        cover: '',
        cate_id: ''
      },
      rules: {
        agent_id: [{ required: true, message: '请选择商户', trigger: 'blur' }],
        title: [{ required: true, message: '请输入文章标题', trigger: 'blur' }],
        cate_id: [{ required: true, message: '请选择文章分类', trigger: 'blur' }],
        cover: [{ required: true, message: '请上传文章封面', trigger: 'change' }],
      },
    }
  },
  methods: {
    getToken () {
      getQNToken().then(response => {
        let data = response
        if (data.code == 200) {
          this.qiniuData.token = data.data.token
        }
      }).catch(error => {
        console.log(error);
      })
    },
    getMerchantsList () {
      merchantsList().then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          console.log(_data)
          this.merchantsListDropDownData = _data
        }
      }).catch(error => {
        console.log(error);
      })
    },

    initPage () {
      this.getToken()
      // this.getMerchantsList()
      let params = this.$route.query
	  console.log(params,'params')
      if (params.type == 'add') {
        this.articleForm = {
          cover: '',
          cate_id: '',
          content: '',
          link: '',
          sort: '',
					agent_id: '',
        }
      }
      if (params.cate_id) {
        this.articleForm.cate_id = Number(params.cate_id)
      }
      if (params.type) {
        this.type = params.type
      }

      if (params.type && params.type !== 'add' && params.article_id) {
        this.type = params.type
        this.article_id = params.article_id
        console.log(params.agent_id)
				this.articleForm.agent_id = parseInt(params.agent_id)
        this.getInfo()
      }
    },

    getInfo () {
		console.log(8888888)
      this.fullscreenLoading = true;
      let params = {
        article_id: this.article_id
      }
      detailArticle(params).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.articleForm = Object.assign({}, this.articleForm, _data)
        }
        this.fullscreenLoading = false
      }).catch(error => {
        console.log(error);
        this.fullscreenLoading = false
      })
    },
    beforeAvatarUpload (file) {
      let name = new Date().getTime() + "_" + file.name   //给file.name加上时间戳保证图片命名不重复
      console.log(name)
      this.qiniuData.key = name;        // 通过设置key为文件名，上传到七牛中会显示对应的图片名
      // debugger
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt10M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt10M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt10M;
    },
    uploadDocPhotoSuccess (res) {
      console.log(res)
      this.articleForm.cover = res.key;
    },

    saveForm (form) {
      this.$refs[form].validate((valid, object) => {
        if (!valid) {
          let item;
          for (let key in object) {
            item = object[key][0]
            break
          }
          this.$message({
            type: 'error',
            message: item.message
          });
          return
        } else {
          let params = {}
          params = { ...this.articleForm }

          if (!params.content && !params.link) {
            this.$message({
              type: 'error',
              message: '文章内容、文章链接必填其中一项！'
            });
            return
          }

          if (this.type == 'add') {
            addArticle(params).then(response => {
              let data = response
              if (data.code == 200) {

                this.$message({
                  type: 'success',
                  message: '文章发布成功！'
                });
                this.type = 'view'
                this.article_id = data.data.id
              }
              this.fullscreenLoading = false
              this.$router.back()
            }).catch(error => {
              console.log(error);
              this.fullscreenLoading = false
            })
          } else {
            //编辑
            editArticle(params).then(response => {
              let data = response
              if (data.code == 200) {
                this.$message({
                  type: 'success',
                  message: '修改成功！'
                });

                this.$store.dispatch('tagsView/delView', this.$route)
                this.type = 'view'
                this.$router.back()
              } else {
                this.$message({
                  type: 'error',
                  message: data.message
                });
              }
              this.fullscreenLoading = false
            }).catch(error => {
              this.$message({
                type: 'error',
                message: error.response.data.message
              });
              this.fullscreenLoading = false
            })
          }
          this.fullscreenLoading = true
        }
      })
    },
    cancelEdit () {
      if (this.id) {
        this.type = 'view'
      }
    },
    cancelForm () {
      this.$refs['articleForm'].resetFields()
      this.articleForm.cate_id = ''
      this.type = 'view'
      this.$router.go(-1)
    }

  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    '$route' (newV, oldV) {
      this.initPage()
    }
  },
  filters: {
    filterImg (img) {
      if (img.indexOf('http') > -1) {
        return img
      } else {
        return config.qiniuUrl + img;
      }
    }
  }
}
</script>

<style lang="scss">
	.avatar {
		height: 200px;
		width: auto;
	}
	
	.el-input.is-disabled {
		.el-input__inner {
			border: none;
			background: transparent;
			color: #333;
		}
		
		.el-icon-arrow-down {
			display: none;
		}
		
		.el-input__suffix {
			display: none;
		}
	}
	
	.preContent {
		margin: 0;
		overflow: auto;
		white-space: pre-wrap;
		border: 1px solid #ececec;
		
		p {
			margin: 0;
			padding: 0;
		}
	}
	
	.imgUpload {
		background: url("../../assets/icon_upload.png") no-repeat;
		background-position: center;
		width: 160px;
		height: 110px;
		background-size: cover;
		
	}
	
	.width278 {
		width: 578px;
		
		img {
			width: 100%;
			height: auto;
		}
	}
	
	.el-form-item {
		margin-bottom: 30px;
	}
	
	.bbox {
		.el-form-item {
			display: flex;
			align-items: center;
			
			.el-form-item__content {
				margin-left: 0 !important;
			}
		}
	}
</style>
